import { defineStore } from 'pinia'

export const useFacilityStore = defineStore('facilityStore', {
  state: () => ({
    list: {},
    selected: {},
    isMap: false,
    isFilter: false,
    searchGlobal: '',

    filter: {
      search: '',
      name: '',
      facility_types: [],
      address: [],
      is_ng: false,
      is_ng_item: false,
      is_new: false,
      is_campaign: false,
      space_event: [],
      is_space_event_not_null: false,
      weekday_price: [0, 0],
      weekend_price: [0, 0],
      holiday_price: [0, 0],
      event_date: {
        start: '',
        end: '',
      },
      cate: '',
      is_bookmark: false,
    },

    options: {
      itemsPerPage: 20,
      page: 1,
      sortBy: [],
      orderBy: [],
    },

    reset: {
      search: '',
      name: '',
      facility_types: [],
      address: [],
      is_ng: false,
      is_ng_item: false,
      is_new: false,
      is_campaign: false,
      space_event: [],
      is_space_event_not_null: false,
      weekday_price: [0, 0],
      weekend_price: [0, 0],
      holiday_price: [0, 0],
      event_date: {
        start: '',
        end: '',
      },
      cate: '',
      is_bookmark: false,
    },
  }),

  persist: {
    storage: persistedState.sessionStorage,
    paths: ['selected', 'isMap'],
  },

  actions: {
    async filterFacility(params: any, isPublic?: boolean) {
      if (isPublic) {
        return await useApiPublic<any>('/facilities/filter', {
          method: 'POST',
          body: params,
        })
      }
      else {
        return await useApi<any>('/facilities/filter', {
          method: 'POST',
          body: params,
        })
      }
    },

    async fetches(params: any) {
      return await useApi<any>(createUrl('/facilities', {
        query: params,
      }))
    },

    async fetch(id: string, isPublic?: boolean) {
      if (isPublic)
        return await useApiPublic<any>(`/facilities/id/${id}`)

      else
        return await useApi<any>(`/facilities/${id}`)
    },

    async findId(id: number, isPublic?: boolean) {
      if (isPublic)
        return await useApiPublic<any>(`/facilities/find/${id}`)

      else
        return await useApi<any>(`/facilities/find/${id}`)
    },

    async fetchsNew(isPublic?: boolean) {
      if (isPublic)
        return await useApiPublic<any>('/facilities/new')

      else
        return await useApi<any>('/facilities/new')
    },

    async fetchRelatedFacility(params: any) {
      return await useApiPublic<any>('/facilities/related', {
        method: 'POST',
        body: params,
      })
    },

    async nearBySearch(params: any) {
      return await $apiPublic<any>('/facilities/nearby', {
        method: 'POST',
        body: params,
      },
      )
    },

    async fetchWithOwner() {
      return await useApi<any[]>('/facilities/withOwner')
    },

    async fetchByOwner(id: number) {
      return await $api(`/facilities/byOwner/${id}`)
    },

    async search(data: any) {
      return await useApi<any>('/facilities/search', {
        params: data,
      })
    },

    async bookmark(params: any) {
      return await $api<any>('/facility_bookmarks', {
        method: 'POST',
        body: params,
      })
    },

    async rank(isPublic?: boolean) {
      if (isPublic)
        return await useApiPublic('/facilities/rank')

      else
        return await useApi('/facilities/rank')
    },

    async rankUpdate(params: any) {
      return await $api<any>('/facilities/rank_update', {
        method: 'POST',
        body: params,
      })
    },

    async add(data: any) {
      return await $api('/facilities', {
        method: 'POST',
        body: data,
      })
    },

    async update(data: any) {
      return await $api(`/facilities/${data.id}`, {
        method: 'PUT',
        body: data,
      })
    },

    async delete(id: number) {
      return await $api(`/facilities/${id}`, {
        method: 'DELETE',
      })
    },

    async uploadFiles(id: number, data: any) {
      return await $api(`/facilities/files/${id}`, {
        method: 'POST',
        body: data,
      })
    },

    async uploadImages(id: number, data: any) {
      return await $api(`/facilities/images/${id}`, {
        method: 'POST',
        body: data,
      })
    },

    async uploadFacilityImage(data: any) {
      return await $api(`/facilities/image_comment/${data.id}`, {
        method: 'POST',
        body: data,
      })
    },

    async deleteFile(id: number) {
      return await $api(`/facilities/file/delete/${id}`)
    },

    async download(data: any) {
      return await $api('/facilities/download', {
        params: data,
      })
    },

    async upload(data: any) {
      return await $api('/facilities/upload', {
        method: 'POST',
        body: data,
      })
    },

    async geoLocationUpload(data: any) {
      return await $api('/facilities/geolocation', {
        params: data,
      })
    },
  },
})
